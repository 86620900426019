import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import SubPageNav from '../../../components/SubPageNav/SubPageNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'

const HombuyersJourneyStep5Page = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      homebuyerImg: file(relativePath: { eq: "familyPorch.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      view='buyer'
      breadcrumb={{
        text: <FormattedMessage id='buyerNav.dashboard' />,
        path: '/new-home-buyer/dashboard/'
      }}
      header={<FormattedMessage id='buyerNav.steps' />}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'buyerNav.step5' })}
      />
      <Container>
        <Row>
          <Col>
            <SubPageNav
              links={[
                {
                  text: <FormattedMessage id='buyerNav.step1' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-1/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step2' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-2/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step3' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-3/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step4' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
                },
                {
                  text: <FormattedMessage id='buyerNav.step5' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-5/',
                  active: true
                },
                {
                  text: <FormattedMessage id='buyerNav.step6' />,
                  path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/'
                }
              ]}
            />
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col md={12}>
            <h2>
              <FormattedMessage id='nav.step' /> 5{' '}
              <span className='thin'>
                <FormattedMessage id='buyerNav.step5' />
              </span>
            </h2>
            <p>
              <FormattedMessage id='homebuyersStep5.contentA' />
            </p>
          </Col>
        </Row>

        <Row className='pageRow'>
          <Col md={12}>
            <h3 className='sm'>
              <FormattedMessage id='homebuyersStep5.headerA' />
            </h3>
            <p>
              <FormattedMessage id='homebuyersStep5.contentB' />
              <br />
              <FormattedMessage id='homebuyersStep5.contentC' />
            </p>
          </Col>
        </Row>

        <Row className='pageRow' align='center'>
          <Col lg={6} md={12}>
            <Img fluid={data.homebuyerImg.childImageSharp.fluid} alt='' />
          </Col>

          <Col lg={6} md={12}>
            <h3 className='sm'>
              <FormattedMessage id='homebuyersStep5.headerB' />
            </h3>
            <p>
              <FormattedMessage id='homebuyersStep5.contentD' />
            </p>
            <ol className='largeNums'>
              <li>
                <FormattedMessage id='homebuyersStep5.list01A' />
              </li>
              <li>
                <FormattedMessage id='homebuyersStep5.list01B' />
              </li>
              <li>
                <FormattedMessage id='homebuyersStep5.list01C' />
              </li>
              <li>
                <FormattedMessage id='homebuyersStep5.list01D' />
              </li>
            </ol>

            <h3 className='sm'>
              <FormattedMessage id='homebuyersStep5.headerC' />
            </h3>
            <p>
              <FormattedMessage id='homebuyersStep5.contentE' />
            </p>
            <ol className='largeNums'>
              <li>
                <FormattedMessage id='homebuyersStep5.list02A' />
              </li>
              <li>
                <FormattedMessage id='homebuyersStep5.list02B' />
              </li>
            </ol>
            <p>
              <FormattedMessage id='homebuyersStep5.contentF' />
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='buyerNav.step4' />,
                path: '/new-home-buyer/steps-in-home-buyers-journey/step-4/'
              }}
              nextLink={{
                text: <FormattedMessage id='buyerNav.step6' />,
                path: '/new-home-buyer/steps-in-home-buyers-journey/step-6/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default HombuyersJourneyStep5Page
